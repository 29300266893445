
        // This is a temporary generated file. Changes to this file will be overwritten eventually!
        import React from "react";

        import Layout from "../src/components/layout";

        import Hero from "../src/components/Hero";

        import { ContentProvider } from "../contexts/ContentContext";

        // Sections
        import ContactForm from '../src/layouts/ContactForm';

        const Page = ({ pageContext }) => {
        const {
            page: { 
              databaseId,
              HeroSection,
              id,
              isFrontPage,
              nodeType,
              PageBuilder,
              PreFooter,
              PageCptFields,
              seo,
              slug,
              status,
              title,
              uri
            },
        } = pageContext;

        const layouts = PageBuilder.layouts || [];
        
        return (
            <ContentProvider value={{ databaseId, HeroSection, id, isFrontPage, nodeType, PageBuilder, PageCptFields, PreFooter, seo, slug, status, title, uri }}>
                <Layout>
                    <Hero data={HeroSection} />
                    {layouts.map((layout, index) => {
                        
                                                if (layout.fieldGroupName === 'Page_Pagebuilder_Layouts_ContactForm') {
                                                    return <ContactForm {...layout} key={index} />;
                                                }
                                                
                })}
                </Layout>
            </ContentProvider>
        );
        }

        export default Page
    