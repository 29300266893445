import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Form from "./Form";

const Contact = styled.div`
  padding: 6rem 1.5rem;
  background-color: #fcf7f7;
`;

const Container = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    width: 55%;
    margin: auto;
  }
`;
const Title = styled.h1`
  font-family: ${props => props.theme.fonts.medium};
  margin-bottom: 3rem;
  font-size: 4rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 6.5rem;
  }
`;
const ContactForm = ({ title, titleColor }) => {
  return (
    <Contact>
      <Container>
        <Title
          css={css`
            color: ${titleColor};
          `}
        >
          {title}
        </Title>
        <Form />
      </Container>
    </Contact>
  );
};

export default ContactForm;
